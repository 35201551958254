// require("https://www.reitsymposium.com/js/reits-main.js");
// require("company_custom/reits-main.js")
require("company_custom/reits-webcast-menu.js")
require("company_custom/poll.js")
document.addEventListener("turbolinks:load", () => {
  let pollModal = document.querySelector("#pollModal")
  if (pollModal && pollModal.dataset.currentTime && pollModal.dataset.startTime && pollModal.dataset.endTime) {
    let isPollShown = false
    let timerInterval = 10000
    let currentTime = parseInt(pollModal.dataset.currentTime)
    let startTime = parseInt(pollModal.dataset.startTime)

    let timeChecker = function() {
      if (isPollShown) {
        return
      }

      currentTime += (timerInterval/1000)
      if (startTime <= currentTime){
        showPollButton()
      }
    }

    let showPollButton = function() {
      if (isPollShown) {
        return;
      }

      $("div.poll-button-wrapper").fadeIn("slow", function() {
        $(this).parent().removeClass('d-none');
        $('.wc-button-outer-wrapper').addClass('style-btn');
        $('.one-line').removeClass('col-xl-8').addClass('col-xl-6');
      });

      isPollShown = true;
    }

    if (currentTime >= startTime) {
      showPollButton()
      return
    }

    setInterval(function() {
      timeChecker()
    }, timerInterval)
  }  
})


